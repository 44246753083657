import { Modal, Stack, Paper, styled, Box } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useState } from "react";
import { updateLead } from "apis/lead";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { dateFormatter } from "utils/dateFormatter";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  width: "50%",
  overflowY: "auto",
  p: 2,
  borderRadius: 3,
  "&::-webkit-scrollbar": {
    display: "none",
  },
};
const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  // textAlign: "center",
  display: "flex",
  justifyContent: "space-between",
}));

const LeadFeesEditModal = ({ open, handleModal, month, lead, setLead }) => {
  const [editedPlan, setEditedPlan] = useState(month.remainingAmount || month.amount);
  const [token] = useState(localStorage.getItem("user-secret"));
  const [showCalender, setShowCalender] = useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [skip, setSkip] = useState(false);
  const [error, setError] = useState("");

  const editLead = async (month) => {
    try {
      let newFees = JSON.parse(
        JSON.stringify(lead.feesStats.EMI_stats.filter((old) => old.id === month.id)[0])
      );
      const index = lead.feesStats.EMI_stats.findIndex((old) => old.id === month.id);
      let lastMonth = null;
      if (index > 0) {
        lastMonth = JSON.parse(JSON.stringify(lead.feesStats.EMI_stats[index - 1]));
      }
      if (
        newFees.remainingAmount &&
        editedPlan < parseInt(newFees.remainingAmount) &&
        !nextPaymentDate &&
        !skip
      ) {
        setShowCalender(true);
        return;
      } else if (editedPlan < parseInt(newFees.amount) && !nextPaymentDate && !skip) {
        setShowCalender(true);
        return;
      } else {
        lead.feesStats.EMI_stats = lead.feesStats.EMI_stats.map((old) => {
          if (old.id === month.id) {
            if (
              !lastMonth &&
              (editedPlan < parseInt(newFees?.amount) ||
                editedPlan < parseInt(newFees?.remainingAmount))
            ) {
              old.remainingAmount = newFees.remainingAmount
                ? (parseInt(old.remainingAmount) - parseInt(editedPlan)).toFixed(2)
                : (parseInt(old.amount) - parseInt(editedPlan)).toFixed(2);
            }
            if (
              (lastMonth &&
                parseInt(lastMonth.amount) <= parseInt(lead?.feesStats?.installmentAmount) &&
                editedPlan < parseInt(newFees.amount)) ||
              editedPlan < parseInt(newFees.remainingAmount)
            ) {
              old.remainingAmount = parseInt(newFees.remainingAmount)
                ? (parseInt(old.remainingAmount) - parseInt(editedPlan)).toFixed(2)
                : (parseInt(old.amount) - parseInt(editedPlan)).toFixed(2);
            }
            old.amount =
              lastMonth &&
              parseInt(lastMonth?.amount) > parseInt(lead?.feesStats?.installmentAmount) &&
              !editedPlan > parseInt(lead?.feesStats?.installmentAmount)
                ? parseInt(old.amount) - editedPlan
                : lastMonth &&
                  parseInt(lastMonth.amount) > parseInt(lead?.feesStats?.installmentAmount)
                ? parseInt(old.amount) - editedPlan
                : parseInt(newFees.remainingAmount)
                ? parseInt(old.amount) + parseInt(editedPlan)
                : parseInt(editedPlan);
            old.nextPaymentDate = nextPaymentDate;
            lead.feesStats.collectedFees =
              lastMonth && parseInt(lastMonth.amount) > parseInt(lead?.feesStats?.installmentAmount)
                ? lead.feesStats.collectedFees
                : editedPlan > parseInt(newFees.amount)
                ? lead.feesStats.collectedFees
                : parseInt(lead.feesStats.collectedFees) + parseInt(editedPlan);
            lead.feesStats.balance = lead.feesStats.totalFees - lead.feesStats.collectedFees;
            return old;
          } else {
            return old;
          }
        });
        const response = await updateLead({ lead }, token);
        if (response.status === 200) {
          setLead(lead);
          setNextPaymentDate("");
          setSkip(false);
          handleModal();
          setShowCalender(false);
        } else {
          setError(response.error.message);
        }
      }
    } catch (error) {
      console.log("Error on LeadFeesEditModal.js:", error.message);
    }
  };
  const handleDateClick = (pickedDateTime) => {
    const date = new Date(pickedDateTime.$d);
    let utcDateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
    setNextPaymentDate(utcDateString);
  };

  const handleSkip = () => {
    setSkip(true);
    setShowCalender(false);
  };
  return (
    <Modal
      open={open}
      onClose={handleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <Box sx={style}>
        <MDBox display="flex" alignItems="center" justifyContent="end">
          <MDButton variant="text" color="error" onClick={() => handleModal()}>
            CLOSE
          </MDButton>
        </MDBox>
        <MDBox>
          <p></p>
        </MDBox>
        {showCalender && (
          <MDBox my={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Please select next payement date"
                defaultValue={dayjs()}
                onChange={(e) => handleDateClick(e)}
              />
            </LocalizationProvider>
            {!nextPaymentDate && (
              <MDButton variant="text" color="error" onClick={() => handleSkip()}>
                Skip
              </MDButton>
            )}
          </MDBox>
        )}
        <Stack direction="row" spacing={2} alignItems="center">
          <DemoPaper
            variant="outlined"
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <MDTypography variant="h6">{month.month}</MDTypography>
            {nextPaymentDate && (
              <MDBox
                display="flex"
                alignItems="center"
                sx={{
                  backgroundColor: "#f4f4f4",
                  borderRadius: 2,
                }}
                px={2}
              >
                <MDTypography variant="body2">{dateFormatter(nextPaymentDate)}</MDTypography>
                <MDTypography
                  variant="h6"
                  ml={2}
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => setNextPaymentDate("")}
                >
                  X
                </MDTypography>
              </MDBox>
            )}

            <MDBox display="flex" alignItems="center">
              <span style={{ color: month.status ? "green" : "red" }}>₹ </span>
              {month.status ? (
                <span style={{ color: "green" }}>{month.amount}</span>
              ) : (
                <MDInput
                  value={editedPlan}
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  color={month.status ? "success" : "error"}
                  style={{ width: "80px", textAlign: "center" }}
                  inputProps={{ min: 0, style: { textAlign: "center" } }}
                  onChange={(e) => setEditedPlan(e.target.value)}
                />
              )}
              <MDButton variant="text" color="success" onClick={() => editLead(month)}>
                Done
              </MDButton>
            </MDBox>
          </DemoPaper>
        </Stack>
      </Box>
    </Modal>
  );
};

export default LeadFeesEditModal;
