export const dateFormatter = (timestamp, onlyDate) => {
  try {
    if (!timestamp) {
      return;
    }
    const date = new Date(timestamp);

    const timeZoneOffset = date.getTimezoneOffset();
    const timeZone = timeZoneOffset === 0 ? "UTC" : "Asia/Kolkata";

    let options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "Asia/Kolkata", // Correctly apply Indian time zone
    };

    if (!onlyDate) {
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = true; // Ensures 12-hour format with AM/PM
    }

    return new Intl.DateTimeFormat("en-IN", options).format(date);
  } catch (error) {
    console.log(error);
    return "N/A";
  }
};
 