export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const VERSION = {
  v1: "v1",
};

export const ENDPOINTS = {
  signIn: `/api/${VERSION.v1}/crm/user/sign-in`,
  signUp: `/api/${VERSION.v1}/crm/user/sign-up`,
  getProfile: `/api/${VERSION.v1}/crm/user/getProfile`,
  getLeads: `/api/${VERSION.v1}/crm/lead/getLeads`,
  getNewLead: `/api/${VERSION.v1}/crm/lead/getNewLead`,
  getTodayDemos: `/api/${VERSION.v1}/crm/lead/getTodayDemos`,
  getFollowUps: `/api/${VERSION.v1}/crm/lead/getFollowUps`,
  updateLead: `/api/${VERSION.v1}/crm/lead/updateLead`,
  getTodayCalls: `/api/${VERSION.v1}/crm/lead/getTodayCalls`,
  getTotal: `/api/${VERSION.v1}/crm/lead/getTotal`,
  getNotPicked: `/api/${VERSION.v1}/crm/lead/getNotPicked`,
  getNewLeadCount: `/api/${VERSION.v1}/crm/lead/getNewLeadCount`,
  auth: `/auth`,
  createLead: `/api/${VERSION.v1}/crm/lead/createLead`,
  getLeadsWithCompletedFees: `/api/${VERSION.v1}/crm/lead/getLeadsWithCompletedFees`,
  getLeadsWithPendingFees: `/api/${VERSION.v1}/crm/lead/getLeadsWithPendingFees`,
  getOldFollowUps: `/api/${VERSION.v1}/crm/lead/getOldFollowUps`,
  updateStage: `/api/${VERSION.v1}/crm/lead/updateStage`,
};

export const STATS = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const RESPONSE = [
  "Not picked",
  "Not interested",
  "Not Connecting",
  "Demo Scheduled",
  "Did not inquire",
  "Incoming call",
  "Switch off",
  "Wrong Number",
  "Temporary out of service",
  "Callback later",
  "Contacted",
];

export const RESPONSE_COLORS = {
  "not picked": "primary",
  "not interested": "secondary",
  "not connecting": "warning",
  "demo scheduled": "success",
  "did not inquire": "error",
  "switch off": "error",
  "wrong number": "error",
  "temporary out of service": "error",
  "callback later": "info",
  contacted: "dark",
  picked: "success",
  "not valid": "dark",
  "not reachable": "error",
  "call later": "info",
  "call not connected": "error",
  "incomming not available": "error",
};

export const DEMO = [
  "Demo Done",
  "Demo not done",
  "Demo Re-scheduled",
  "Joined",
  "Callback later",
  "Not picked",
];

export const COURSES = [
  "(CISE-All-KECT) Certified Information Security Expert - All Levels - Kohat Enclave Classroom Training",
  "Ethical Hacking",
  "Web Devlopment",
];

export const STAGES = [
  { stage: "New Lead", color: "#4CAF50", mauticId: 4 },
  { stage: "Initiated", color: " #D81B60", mauticId: 5 },
  { stage: "Shared Details", color: "#49a3f1", mauticId: 2 },
  { stage: "Demo Scheduled", color: "#FFA726", mauticId: 3 },
  { stage: "Demo Completed", color: "#EF5350", mauticId: 6 },
  { stage: "Physical Visit", color: "#42424a", mauticId: 8 },
  { stage: "Converted", color: "#4CAF50", mauticId: 7 },
  { stage: "Not Interested", color: "#42424a", mauticId: 1 },
];

export const NOT_PICKED_RESPONSE = [
  "Not Picked",
  "Not Reachable",
  "Switched Off",
  "Call Not Connected",
  "Incomming Not Available",
];

export const GENDERS = ["Male", "Female"];

export const QUALIFICATIONS = ["Technical", "Non-Technical"];

export const PROFILE_COLORS = ["primary", "warning", "info", "secondary", "error"];

export const MONTHS_NAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
