import MDButton from "components/MDButton";
import { PROFILE_COLORS } from "config";
import { memo } from "react";
import { profileSetter } from "utils/profileSetter";

const isEqual = (prevProps, nextProps) => {
  return prevProps.lead._id === nextProps.lead._id;
};

const LeadProfileImage = ({ lead }) => {
  return (
    <MDButton
      variant="gradient"
      color={PROFILE_COLORS[Math.floor(Math.random() * PROFILE_COLORS.length)]}
      iconOnly
      circular
    >
      {profileSetter(lead.name)}
    </MDButton>
  );
};

export default memo(LeadProfileImage, isEqual);
