import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import { STAGES } from "../../../../config";
import { updateStage } from "apis/lead";

function BaseLayout({ children, lead, setLead, token }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const updateLeadFun = async (stage) => {
    try {
      const response = await updateStage(
        { lead: { ...lead, stage, previousStage: lead.stage } },
        token
      );
      if (response.status === 200) {
        setLead(response.data.lead);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("horizontal")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    updateLeadFun(event.target.innerText);
    setTabValue(newValue);
  };

  return (
    <MDBox>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          {/* <MDBox display="flex" m={1}>
            <Icon
              sx={{
                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={() => close(lead)}
            >
              close
            </Icon>
            <MDButton
              variant="gradient"
              color="error"
              sx={{
                fontWeight: ({ typography: { fontWeightBold } }) => fontWeightBold,
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={() => close()}
            >
              close
            </MDButton>
          </MDBox> */}
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={lead.stage !== "Not Interested" && handleSetTabValue}
              sx={{ backgroundColor: "white !important" }}
            >
              {!lead?.stage || lead?.stage.toLowerCase() === "new lead" ? (
                <Tab
                  label={"New Lead"}
                  sx={{
                    backgroundColor: "#43A047",
                    color: "white !important ",
                  }}
                />
              ) : lead?.stage.toLowerCase() === "not interested" ? (
                <Tab
                  label={"Not Interested"}
                  sx={{
                    backgroundColor: "#42424a",
                    color: "white !important ",
                  }}
                />
              ) : (
                STAGES.map((stage, i) => {
                  if (
                    stage?.stage.toLowerCase() !== "new lead" &&
                    stage?.stage.toLowerCase() !== "not interested"
                  ) {
                    return (
                      <Tab
                        label={stage.stage}
                        sx={{
                          backgroundColor:
                            lead.stage.replace("\n", "").toLowerCase() ===
                              stage.stage.toLowerCase() || lead.stage === stage.mauticId
                              ? stage.color
                              : "#CED4DA",
                          color: "white !important ",
                          borderTopRightRadius: i !== STAGES.length - 2 && "0px !important",
                          borderBottomRightRadius: i !== STAGES.length - 2 && "0px !important",
                          borderTopLeftRadius: i !== STAGES.length - 6 && "0px !important",
                          borderBottomLeftRadius: i !== STAGES.length - 6 && "0px !important",
                        }}
                        key={stage?.stage.toLowerCase()}
                      />
                    );
                  }
                })
              )}
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      {children}
    </MDBox>
  );
}

BaseLayout.defaultProps = {
  stickyNavbar: false,
};

BaseLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
