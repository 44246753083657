import { useState, memo } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LeadProfileImage from "./LeadProfileImage";

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.lead.stage === nextProps.lead.stage && prevProps.lead.name === nextProps.lead.name
  );
};

const Header = ({ lead, setLead, updateLeadFun }) => {
  const [visible, setVisible] = useState(
    lead.stage.toLowerCase() === "not interested" ? true : false
  );

  const updateLeadStage = async () => {
    try {
      setVisible(!visible);
      setLead({
        ...lead,
        previousStage: lead.stage,
        stage: !visible ? "Not Interested" : lead?.previousStage,
      });
      updateLeadFun(!visible ? "Not Interested" : "previousStage", lead);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card id="name-section">
      <MDBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <LeadProfileImage lead={lead} />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {lead.name}
              </MDTypography>
              <MDTypography
                variant="h6"
                color="text"
                fontWeight="medium"
                sx={{
                  fontSize: {
                    xs: "0.8rem",
                  },
                }}
              >
                {new Date(lead.created).toLocaleDateString() +
                  ", " +
                  new Date(lead.created).toLocaleTimeString()}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            <MDBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <MDTypography variant="caption" fontWeight="regular">
                Not Interested
              </MDTypography>
              <MDBox ml={1}>
                <Switch checked={visible} onChange={updateLeadStage} />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default memo(Header, areEqual);
